import React from "react";
import Link from "next/link";
import Head from "next/head";

const Custom404 = () => {
    return <>
        <Head>
            <meta charSet="utf-8"/>
            <link rel="shortcut icon" type="image/png" href="/assets/imgs/logos/favicon.png"/>
            <link rel="apple-touch-icon" href="/assets/imgs/logos/app/apple-touch-icon.png"/>
            <link
                rel="apple-touch-icon"
                sizes="48x48"
                href="/assets/imgs/logos/app/x48.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="72x72"
                href="/assets/imgs/logos/app/x72.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="96x96"
                href="/assets/imgs/logos/app/x96.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="144x144"
                href="/assets/imgs/logos/app/x144.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="168x168"
                href="/assets/imgs/logos/app/x168.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="192x192"
                href="/assets/imgs/logos/app/x192.png"
            />
            <meta name="msapplication-TileColor" content="#ffffff"/>
            <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
            <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <meta name="author" content="ANIVIA"/>
            <meta name="theme-color" content="#007aff"/>
            <link rel="manifest" href="/manifest.json"/>
            <meta name="description"
                  content="ANIVIA te Heusden-Zolder helpt je met software toepassingen zoals websites, web applicaties en mobiele applicaties. Daarnaast bieden ze ook IT consultants aan."></meta>
            <title>404 - Page Not Found</title>
        </Head>
        <section className="pt-20 pb-32">
            <div className="container text-center" style={{position: "relative"}}>
                <img
                    className="mb-4 mx-auto md:max-w-md mb-12 animate__animated animate__fadeIn"
                    src="/assets/imgs/illustrations/error2.webp"
                    alt="Error image"
                />

                <span
                    className="text-4xl text-blue-500 font-bold font-heading animate__animated animate__fadeIn"
                    data-wow-delay=".1s"
                >
          Error 404
        </span>
                <h2
                    className="mb-2 text-4xl font-bold font-heading animate__animated animate__fadeIn"
                    data-wow-delay=".1s"
                >
                    Er is iets misgegaan!
                </h2>
                <p
                    className="mb-6 text-blueGray-400 animate__animated animate__fadeIn"
                    data-wow-delay=".1s"
                >
                    Sorry, maar we kunnen deze pagina niet openen.
                </p>
                <div>
                    <Link
                        href="/"
                        className="block sm:inline-block py-4 px-8 mb-4 sm:mb-0 sm:mr-3 text-xs text-white text-center font-semibold leading-none bg-blue-400 hover:bg-blue-500 rounded animate__animated animate__fadeIn"
                        data-wow-delay=".1s">

                        Ga terug naar de Startpagina

                    </Link>
                    <Link
                        href="/contact"
                        className="block sm:inline-block py-4 px-8 text-xs text-blueGray-500 hover:text-blueGray-800 text-center font-semibold leading-none bg-blueGray-50 hover:bg-blueGray-100 rounded animate__animated animate__fadeIn"
                        data-wow-delay=".1s">

                        Contacteer Ons

                    </Link>
                </div>
            </div>
        </section>
    </>;
};

export default Custom404;
